<template>
  <v-container class="fill-height">
    <v-row justify="center" align="center">
      <v-col align-self="center" class="d-flex justify-center">
        <v-card elevation="5" max-width="700">
          <v-card-title primary-title>
            <v-icon color="orange">
              mdi-traffic-cone
            </v-icon>
            ระบบควบคุมการตั้งจุดตรวจ
          </v-card-title>
          <v-card-subtitle>
            Thai Police Checkpoint Control
          </v-card-subtitle>
          <v-card-text>
            อยู่ระหว่างการพัฒนา การเชื่อมโยงข้อมูลกับระบบ PTM
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  methods: {
    openManDialog() {},
  },
  data() {
    return {
      logo: () => import('@/assets/logo.png'),
      files: [
        { name: 'คู่มือการใช้งาน', link: 'https://1drv.ms/u/s!Ag0ZeBSbNY1ThaRAozj-9eet4u64YQ?e=eQXufj' },
        { name: 'โปรแกรมอ่านบัตรประชาชน', link: 'https://1drv.ms/u/s!Ag0ZeBSbNY1ThaRB80nHhIYzsdVUVA?e=8LTXDb' },
      ],
    }
  },
  components: {},
}
</script>
